import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import BookingList from './BookingList';
import UserLayout from 'src/layouts/UserLayout';
import { Download } from '@mui/icons-material';
import Loader from 'src/components/loader';
import { useParams } from 'react-router-dom';
import { downloadBookingList } from 'src/store/actions/application';

const BookingSettings = () => {
  const params = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const projectUuid = params.uuid;

  const handleExport = async () => {
    setIsSubmitting(true);
    try {
      await downloadBookingList(projectUuid);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <UserLayout showExtraNavItems={true} contentHeightFixed={true}>
      <Stack gap="24px">
        <Card>
          <CardContent>
            <Stack gap="20px">
              <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h6">Бронирование</Typography>
                <Stack direction="row" gap="16px">
                  <Button
                    variant="outlined"
                    startIcon={isSubmitting ? <Loader color="inherit" size="12px" /> : <Download />}
                    onClick={handleExport}
                  >
                    Экспорт
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <BookingList projectUuid={projectUuid} />
        </Card>
      </Stack>
    </UserLayout>
  );
};

export default BookingSettings;
