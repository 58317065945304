import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SelectHookForm } from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { generateRandomPassword } from 'src/utils/common';
import { LOCALIZATION, MESSAGES } from 'src/utils/constant';
import { supportedUserCopyValidationSchema, supportedUserCreateValidationSchema } from 'src/utils/formValidator';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import { TableHeader } from '..';
import { projectAPIs } from 'src/services';

function SupportedUserCreate({ projectUuid, systemRoles, onReloadTable = () => {} }) {
  const [showPassword, setShowPassword] = useState(false),
    [focusedField, setFocusedField] = useState(null),
    [foundUsers, setFoundUsers] = useState({ email: [], query: [] }),
    [userForCopy, setUserForCopy] = useState(null);

  const projects = useSelector((state) => state.application.projects);
  const lang = useSelector((state) => state.application.lang) || LOCALIZATION.ru_RU;

  const renderFoundUser = (props, option) => (
    <Box
      key={option.uuid}
      {...props}
      display="flex"
      flexDirection="column"
      sx={{
        p: 1.5,
        borderRadius: '4px',
        textAlign: 'left',
        gap: '2px',
      }}
    >
      <Typography width="100%" variant="body1" sx={{ fontWeight: 'bold' }}>
        {option.fullName}
      </Typography>
      <Typography width="100%" variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
        {option.email}
      </Typography>
      <Typography width="100%" variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
        {projects?.find((pr) => pr.uuid === option.projectUuid)?.name} ({option.roleName})
      </Typography>
    </Box>
  );
  const defaultValues = {
    password: generateRandomPassword(),
    fullName: '',
    email: '',
    roleUuid: '',
    expiredDate: '',
  };

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(userForCopy ? supportedUserCopyValidationSchema : supportedUserCreateValidationSchema),
  });

  useEffect(() => {
    if (userForCopy) {
      reset({
        fullName: userForCopy.fullName,
        email: userForCopy.email,
        roleUuid: systemRoles.some((r) => r.uuid === userForCopy.roleUuid)
          ? userForCopy.roleUuid
          : systemRoles.find((r) => userForCopy.roleName === r.name)?.uuid || '',
        expiredDate: '',
      });
    } else {
      reset(defaultValues, {
        keepValues: true,
      });
    }
  }, [userForCopy, reset]);

  const handleCreateUser = async (values) => {
    try {
      const body = { ...values, expiredDate: format(values.expiredDate, 'yyyy-MM-dd') };
      await request(`/internal/projects/${projectUuid}/sysusers`, { method: 'POST', body });
      createNotification(MESSAGES[lang].CREATE_SUCCESSFULLY, 'success');
      if (userForCopy) setUserForCopy(null);
      reset(defaultValues);
      onReloadTable();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const findUser = debounce(async (value, type) => {
    const queryParam = { [type]: value };
    const similarUsers = await projectAPIs.getSimilarUser(queryParam);
    console.log(`similarUsers ${type}`, similarUsers);
    setFoundUsers({
      ...foundUsers,
      [type]:
        similarUsers.message?.reduce(
          (acc, user) => [
            ...acc,
            ...user.projectProfiles.map((pr) => ({
              email: user.email,
              fullName: user.fullName,
              projectUuid: pr.projectUuid,
              roleName: pr.roleName,
              roleUuid: pr.roleUuid,
              uuid: user.uuid,
            })),
          ],
          []
        ) || [],
    });
  }, 1000);

  const findUserByEmail = (e) => {
    setUserForCopy(null);
    if (!e.target.value) {
      setFoundUsers({ ...foundUsers, email: [] });
      setUserForCopy(null);
      return;
    }
    if (e.target.value.length < 5) return;
    findUser(e.target.value, 'email');
  };
  const findUserByName = (e) => {
    setUserForCopy(null);
    if (!e.target.value) {
      setFoundUsers({ ...foundUsers, query: [] });
      setUserForCopy(null);
      return;
    }
    if (e.target.value.length < 5) return;
    findUser(e.target.value, 'query');
  };

  console.log('foundUsers', foundUsers);
  console.log('projects', projects);
  console.log('errrrrors', errors);
  console.log('systemRoles', systemRoles);
  return (
    <Stack gap="16px">
      <TableHeader sx={{ color: (theme) => theme.palette.text.primary }}>Личные данные</TableHeader>
      {false ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(handleCreateUser)}>
          <Box sx={{ width: { xs: '100%', md: '70%' } }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        freeSolo
                        disableClearable
                        blurOnSelect
                        options={foundUsers.query}
                        getOptionLabel={(option) => field.value || option.fullName || ''}
                        filterOptions={(a) => a}
                        open={foundUsers.query.length > 0 && focusedField === 'fullName'}
                        onFocus={() => setFocusedField('fullName')}
                        onBlur={() => setFocusedField(null)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Фамилия Имя Отчество"
                            error={errors.fullName ? true : false}
                            helperText={errors.fullName?.message}
                          />
                        )}
                        renderOption={renderFoundUser}
                        onChange={(e, val) => {
                          setUserForCopy(val);
                        }}
                        inputValue={field.value}
                        onInputChange={(e) => {
                          field.onChange(e.target.value);
                          findUserByName(e);
                        }}
                      />
                    );
                  }}
                />
                {/* <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Фамилия Имя Отчество"
                  {...register('fullName')}
                  error={errors.fullName ? true : false}
                  helperText={errors.fullName?.message}
                  onChange={findUserByName}
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Роль"
                  {...register('role')}
                  error={errors.role ? true : false}
                  helperText={errors.role?.message}
                /> */}
                <Controller
                  name="roleUuid"
                  control={control}
                  render={({ field }) => (
                    <SelectHookForm
                      shrink={true}
                      label="Роль"
                      selectProps={{
                        value: field.value,
                        onChange: field.onChange,
                      }}
                      error={errors.roleUuid ? true : false}
                      errorComponent={
                        <>
                          {errors.roleUuid && <FormHelperText error={true}>{errors.roleUuid.message}</FormHelperText>}
                        </>
                      }
                    >
                      {systemRoles.map((value, i) => (
                        <MenuItem key={i} value={value.uuid}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </SelectHookForm>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      freeSolo
                      disableClearable
                      blurOnSelect
                      getOptionLabel={(option) => {
                        return field.value || option.email || '';
                      }}
                      options={foundUsers.email}
                      filterOptions={(a) => a}
                      open={foundUsers.email.length > 0 && focusedField === 'email'}
                      onFocus={() => setFocusedField('email')}
                      onBlur={() => setFocusedField(null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          type="email"
                          label="Email"
                          error={errors.email ? true : false}
                          helperText={errors.email?.message}
                        />
                      )}
                      renderOption={renderFoundUser}
                      onChange={(e, val) => {
                        setUserForCopy(val);
                      }}
                      inputValue={field.value}
                      onInputChange={(e) => {
                        field.onChange(e.target.value);
                        findUserByEmail(e);
                      }}
                    />
                  )}
                />
                {/* <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="email"
                  label="Email"
                  {...register('email')}
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                  onChange={findUserByEmail}
                /> */}
              </Grid>
              {!userForCopy && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Ваш пароль"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('password')}
                    error={errors.password ? true : false}
                    helperText={errors.password?.message}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="expiredDate"
                  control={control}
                  render={({ field }) => (
                    <ReactDatePicker
                      minDate={new Date()}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      customInput={
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Дата окончания доступа"
                          error={errors.expiredDate ? true : false}
                          helperText={errors.expiredDate?.message}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack justifyContent="flex-end" sx={{ height: '100%' }}>
                  <Button type="submit" size="large" variant="contained" fullWidth>
                    добавить
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Stack>
  );
}

export default SupportedUserCreate;
