import { Button, Stack } from '@mui/material';
import moment from 'moment-timezone';
import qs from 'query-string';
import { Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import DateTimeInputMui from 'src/components/date-time-input-mui';
import { DEFAULT_UTC_OFFSET } from 'src/utils/constant';
import { timeZone, timeZoneConverter } from 'src/utils/time';
import classes from './filters.module.sass';
import Input from 'src/components/input';

function MeetingFilters({ onSubmit = () => {} }) {
  const location = useLocation();
  const query = qs.parse(location.search);

  const initialValues = {};

  const handleSubmit = (filters) => {
    const since = timeZoneConverter(
      moment(filters.since),
      DEFAULT_UTC_OFFSET,
      timeZone(moment(filters.since).toDate())
    );
    const till = timeZoneConverter(moment(filters.till), DEFAULT_UTC_OFFSET, timeZone(moment(filters.till).toDate()));
    return onSubmit({
      ...filters,
      ...(filters.since ? { since: since.unix() * 1000 } : {}),
      ...(filters.till ? { till: till.unix() * 1000 } : {}),
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if (!values.since && !values.till) {
          errors.since = 'Required';
          errors.till = 'Required';
        }
        if (values.since && !values.till) {
          errors.till = 'Required';
        }
        if (!values.since && values.till) {
          errors.since = 'Required';
        }
        if (values.since && values.till && moment(values.since).isAfter(moment(values.till))) {
          errors.since = 'Invalid date range';
        }
        return errors;
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Row style={{ alignItems: 'flex-end' }}>
            <Col xs={12} md={6} xl={3}>
              <Stack gap="20px">
                <Input name="from" label="От:" placeholder="ФИО/Организация" />
                <Input name="to" label="Для:" placeholder="ФИО/Организация" />
                <Input name="table" label="Номер стола:" placeholder="123" />
              </Stack>
            </Col>

            <Col xs={12} md={6} xl={3}>
              <DateTimeInputMui name="since" label="C:" className={classes.date} />
              <DateTimeInputMui name="till" label="По:" className={classes.date} />
            </Col>

            <Col xs={12} md={6} xl={3}>
              <Stack gap="30px">
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.button}
                  onClick={() => {
                    form.restart();
                    onSubmit({});
                  }}
                >
                  Очистить фильтры
                </Button>
                <Button variant="contained" type="submit" size="large" className={classes.button}>
                  Искать
                </Button>
              </Stack>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
}

export default MeetingFilters;
