import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import MenuOutlined from '@mui/icons-material/MenuOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useAbility } from 'src/hooks/useAbility';
import UserIcon from 'src/layouts/components/UserIcon';
import { ACCESSES } from 'src/utils/ability';
import { userAPIs } from '../../services';
import { EVENTS, IMAGE_FILE_TYPES, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import VizitStatus from '../status/VizitStatus';
import CompanyForm from './components/company-form';
import Members from './components/members';
import classes from './vizit-section.module.sass';

export const getTimeSlotOptions = () => {
  const startTime = moment().set('hour', 10).set('minute', 0);
  const endTime = moment().set('hour', 21).set('minute', 0);
  return Array.from({ length: endTime.diff(startTime, 'hours') * 3 }, (_, i) => {
    const start = startTime
      .clone()
      .add(i / 3, 'hour')
      .format('HH:mm');
    const end = startTime
      .clone()
      .add(i / 3, 'hour')
      .add(20, 'minute')
      .format('HH:mm');
    return `${start} - ${end}`;
  });
};

export const getTableOptions = () => Array.from({ length: 31 }, (_, i) => i + 1);

function VizitSection({
  projectUuid,
  userUuid,
  isLoadingVizit,
  vizits,
  setVizits,
  onDeletedVizit = () => {},
  onReloadVizit = () => {},
  setMembersVizits,
  isLocked,
}) {
  const lang = LOCALIZATION.ru_RU;
  const ability = useAbility();
  const [isUploading, setIsUploading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [requireLogo, setRequireLogo] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const canManageVizit = ability.can(ACCESSES.MANAGE_VIZIT);

  const handleChange = async (file) => {
    if (isUploading || isLocked) return;
    try {
      setIsUploading(true);
      setRequireLogo(false);
      if (!vizits) {
        setUploadedLogo(file);
      } else {
        const formData = new FormData();
        formData.append('fileDocument', file, file.name);
        const response = await userAPIs.updateLogoVizit(projectUuid, userUuid, formData);
        setVizits(response.message);
        createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const deleteUserVizit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await userAPIs.deleteUserVizit(projectUuid, userUuid);
      setVizits(null);
      onDeletedVizit();
      createNotification(MESSAGES[lang].DELETE_SUCCESSFULLY);
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  const handleDeleteUserVizit = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteUserVizit();
      },
    });
  };

  const onChangeVizitStatus = async (vizit, status) => {
    if (isSubmitting || !status || isLocked) return;
    setIsSubmitting(true);
    try {
      await userAPIs.updateVizitStatus(projectUuid, vizit.uuid, status);
      onReloadVizit();
      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
    } catch (e) {
      console.log(e);
      createErrorNotification(e.message?.error || MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  return isLoadingVizit ? (
    <CircularProgress />
  ) : (
    <Stack className={classes.content} gap="30px">
      <Stack direction="row" gap="8px" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <Stack direction="row" gap="8px" flexWrap="wrap" alignItems="center">
          <Typography variant="h6">Визитка участника</Typography>
          {vizits && <VizitStatus status={vizits.status} />}
        </Stack>
        {vizits && (
          <Stack direction="row" gap="8px" flexWrap="wrap" alignItems="center">
            {(canManageVizit || !isLocked) && (
              <IconButton
                variant="outlined"
                color="secondary"
                onClick={(e) => (anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget))}
                sx={{ borderRadius: '4px', border: '1px solid' }}
              >
                <MenuOutlined />
              </IconButton>
            )}
            <Popover
              open={openMenu}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Stack gap="8px" paddingX="18px" paddingY="12px">
                {!isLocked && (
                  <>
                    {['declined', 'initial'].includes(vizits.status) && (
                      <Button
                        variant="outlined"
                        color="success"
                        endIcon={<UserIcon icon="mdi:check" />}
                        onClick={() => {
                          onChangeVizitStatus(vizits, 'approved');
                        }}
                      >
                        Подтвердить
                      </Button>
                    )}
                    {['approved', 'initial'].includes(vizits.status) && (
                      <Button
                        variant="outlined"
                        color="warning"
                        endIcon={<UserIcon icon="mdi:close" />}
                        onClick={() => {
                          onChangeVizitStatus(vizits, 'declined');
                        }}
                      >
                        Отклонить
                      </Button>
                    )}
                  </>
                )}

                {ability.can(ACCESSES.MANAGE_VIZIT) && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteUserVizit}
                    endIcon={<UserIcon icon="mdi:delete-outline" />}
                  >
                    Удалить
                  </Button>
                )}
              </Stack>
            </Popover>
          </Stack>
        )}
      </Stack>

      <Box>
        <Grid container spacing={8.75} className={classes.userList}>
          {vizits && (
            <>
              <Grid item xs={12} lg={3}>
                {isUploading ? (
                  <CircularProgress />
                ) : vizits?.logo || uploadedLogo ? (
                  <Stack className={classes.imageWrapper}>
                    {!isLocked && (
                      <input
                        // ref={inputFileRef}
                        accept="image/jpg, image/jpeg, image/png"
                        className={classes.inputAvatar}
                        id="logo-file"
                        multiple={false}
                        type="file"
                        onChange={(e) => handleChange(e.target?.files?.[0])}
                      />
                    )}

                    <label htmlFor="logo-file" className={classes.labelImage}>
                      {/* <CameraAltIcon /> */}
                      <img
                        src={
                          vizits?.logo
                            ? vizits?.logo + `?dummy=${new Date().getTime()}`
                            : URL.createObjectURL(uploadedLogo)
                        }
                        className={classes.image}
                        alt="logo"
                      />
                    </label>
                  </Stack>
                ) : isLocked ? (
                  <></>
                ) : (
                  <FileUploader
                    types={IMAGE_FILE_TYPES}
                    children={
                      <div className={classes.uploadZone}>
                        <AddPhotoAlternateIcon />
                        <Typography>загрузить логотип</Typography>
                      </div>
                    }
                    handleChange={handleChange}
                    name="file"
                  />
                )}
                {requireLogo && !uploadedLogo && !vizits && (
                  <p style={{ color: 'red' }}>* {MESSAGES[lang].PLEASE_UPLOAD_LOGO}</p>
                )}
              </Grid>
            </>
          )}

          <Grid item xs={12} lg={vizits ? 9 : 12}>
            {!isSubmitting && (
              <CompanyForm
                projectUuid={projectUuid}
                userUuid={userUuid}
                vizits={vizits}
                setVizits={setVizits}
                uploadedLogo={uploadedLogo}
                setRequireLogo={setRequireLogo}
                setMembersVizits={setMembersVizits}
                isLocked={isLocked}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      {/* {vizits && (
        <>
          <Divider sx={{ margin: '3rem 0' }} />
          <div>
            <Stack direction="row" style={{ alignItems: 'center', marginBottom: 42, gap: '16px' }}>
              <h2 style={{ fontWeight: 'bold', margin: 'auto 0' }}>{TEXT[lang].MEMBERS}</h2>
              {(membersVizits || []).length === 0 && (
                <div className={classes.button} style={{ width: 'fit-content', margin: 0 }}>
                  <Button
                    className={classes.secondaryButton}
                    variant="contained"
                    size="large"
                    endIcon={<AddIcon />}
                    onClick={() => setShowAdd(true)}
                    // disabled={isSubmitting || !isValidForm || !uploadedFile}
                  >
                    {TEXT[lang].ADD_MEMBER}
                  </Button>
                </div>
              )}
            </Stack>
            <Grid container spacing={2}>
              {showAdd && (
                <Members
                  projectUuid={projectUuid}
                  userUuid={userUuid}
                  membersVizits={membersVizits}
                  setMembersVizits={setMembersVizits}
                  setShowAdd={setShowAdd}
                />
              )}
              {(membersVizits || []).map((member, i) => (
                <Members
                  key={i}
                  member={member}
                  projectUuid={projectUuid}
                  userUuid={userUuid}
                  membersVizits={membersVizits}
                  setMembersVizits={setMembersVizits}
                />
              ))}
            </Grid>
          </div>
        </>
      )} */}
    </Stack>
  );
}

export default VizitSection;
