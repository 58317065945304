import { AccountCircle, Check, Clear, Download, Groups, ImageSearch, LocalPrintshop, Store } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionsButton from 'src/components/atoms/button/ActionsButton';
import ButtonLoading from 'src/components/button-loading';
import { MUITable } from 'src/components/mui-table';
import UserStatus from 'src/components/status/UserStatus';
import { accreditationStatuses, DOCUMENT_TYPES, routes } from 'src/constants';
import { useDownloadFile, useLanguage } from 'src/hooks';
import { useAbility } from 'src/hooks/useAbility';
import { projectAPIs, userAPIs } from 'src/services';
import { editUser, updateUserByProjectUuid } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { isSafari, preventSpreadEvent } from 'src/utils/common';
import { BOOKING_STATUS, BOOKING_STATUS_CODES, EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
function BookingList({ projectUuid, queryParams = {} }) {
  const navigate = useNavigate();
  const ability = useAbility();
  const { lang, localizeMessage, localizeText } = useLanguage();

  const currentProject = useSelector((state) => state.application.currentProject);
  const roles = useSelector((state) => state.application.roles);

  const rolesById = useMemo(() => {
    return roles?.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }, [roles]);

  const [showFilter, setShowFilter] = useState(false);

  const [profileModalId, setProfileModalId] = useState(null);

  const canAccrediteUser = ability.can(ACCESSES.ACCREDITE_USER);
  const canPrintBadge = ability.can(ACCESSES.PRINT_BADGE);

  const fetchBookingList = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await projectAPIs.getBookingItems(projectUuid);
    return res.message || [];
  };
  const {
    isLoading: isFetching,
    data: list = [],
    refetch,
  } = useQuery(['fetchBookingList', projectUuid], fetchBookingList, {
    enabled: !!projectUuid,
  });

  const handleChangeStatus = async (applyUuid, code) => {
    try {
      await projectAPIs.editBookingAdmin(projectUuid, applyUuid, { status_code: +code });
      refetch();
    } catch (e) {
      createNotification(e.message?.error || localizeMessage.ERROR);
    } finally {
    }
  };
  const handleDeleteApply = async (applyUuid) => {
    try {
      await projectAPIs.deleteApply(projectUuid, applyUuid);
      refetch();
    } catch (e) {
      createNotification(e.message?.error || localizeMessage.ERROR);
    } finally {
    }
  };
  const headCells = [
    // {
    //   id: 'icons',
    //   numeric: false,
    //   disablePadding: true,
    //   label: '',
    //   renderRow: (row) => {
    //     const hasBadge = row.badgeUuid != '';
    //     const isMerch = row.projectSettings?.[currentProject?.uuid]?.isGivenMerch;
    //     const isSelfRegister = row.accreditedBy == '';
    //     const isBadgePrinted = row.projectSettings?.[currentProject?.uuid]?.isBadgePrinted;
    //     return (
    //       <Stack direction="row" gap="4px">
    //         {isMerch && (
    //           <Tooltip title="Пользователь уже получил мерч">
    //             <Store color="warning" />
    //           </Tooltip>
    //         )}
    //         {isSelfRegister && (
    //           <Tooltip title="Пользователь создал анкета самостоятельно">
    //             <AccountCircle color="black" />
    //           </Tooltip>
    //         )}
    //         {hasBadge && (
    //           <Tooltip title="Бейдж был создан">
    //             <ImageSearch color="success" />
    //           </Tooltip>
    //         )}
    //         {isBadgePrinted && (
    //           <Tooltip title="Бейдж был напечатан">
    //             <LocalPrintshop color="success" />
    //           </Tooltip>
    //         )}
    //       </Stack>
    //     );
    //   },
    // },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Дата создания',
      renderRow: (row) => {
        return (
          <>
            <Typography variant="body2">{moment(row.apply_user.created_at).format('DD.MM.YYYY')}</Typography>
            <Typography variant="body2">{moment(row.apply_user.created_at).format('HH:mm:ss')}</Typography>
          </>
        );
      },
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: 'Создатель',
      renderRow: (row) => (
        <Typography variant="body2">
          {(row.apply_user.owner_last_name || '') +
            ' ' +
            (row.apply_user.owner_first_name || '') +
            ' ' +
            (row.apply_user.owner_middle_name || '')}
        </Typography>
      ),
    },
    {
      id: 'apply_type',
      numeric: false,
      disablePadding: false,
      label: 'Тип заявки',
      renderRow: (row) => <Typography variant="body2">{row.apply_type.localization[lang].name || ''}</Typography>,
    },
    {
      id: 'apply_relation_name',
      numeric: false,
      disablePadding: false,
      label: 'Название',
      renderRow: (row) => (
        <>
          <Typography variant="body2">{row.apply_entity.localization[lang].name || ''}</Typography>
          <Typography variant="body2">({row.apply_location.localization[lang].name})</Typography>
        </>
      ),
    },
    {
      id: 'apply_relation_time',
      numeric: false,
      disablePadding: false,
      label: 'интервал',
      renderRow: (row) => (
        <>
          <Typography variant="body2">{moment(row.apply_time.start_time).format('DD.MM.YY HH:mm-')}</Typography>
          <Typography variant="body2">{moment(row.apply_time.end_time).format('DD.MM.YY HH:mm')}</Typography>
        </>
      ),
    },
    {
      id: 'full_name',
      numeric: false,
      disablePadding: false,
      label: 'Пользователь',
      renderRow: (row) => (
        <Typography variant="body2">
          {(row.apply_user.user_last_name || '') +
            ' ' +
            (row.apply_user.user_first_name || '') +
            ' ' +
            (row.apply_user.user_middle_name || '')}
        </Typography>
      ),
    },
    {
      id: 'mainRoleId',
      numeric: false,
      disablePadding: false,
      label: 'роль',
      renderRow: (row) => {
        return <Typography variant="body2">{row.apply_user.role_name[lang] || ''}</Typography>;
      },
    },
    {
      id: 'extra_info',
      numeric: false,
      disablePadding: false,
      label: 'Доп.инфо',
      renderRow: (row) => {
        return (
          <Typography maxWidth={'150px'} textOverflow="ellipsis" overflow="hidden" variant="body2">
            {row.apply_user.additional_info}
          </Typography>
        );
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'статус',
      renderRow: (row) => {
        let statusColor = 'secondary';
        switch (row.apply_status.code) {
          case BOOKING_STATUS_CODES.CONFIRMED:
            statusColor = 'success';
            break;
          case BOOKING_STATUS_CODES.PENDING:
            statusColor = 'warning';
            break;
          case BOOKING_STATUS_CODES.REJECTED:
            statusColor = 'error';
            break;
          case BOOKING_STATUS_CODES.CANCELLED:
          default:
            statusColor = 'secondary';
            break;
        }
        return (
          <Chip
            label={BOOKING_STATUS[row.apply_status.code][lang]}
            size="small"
            color={statusColor}
            sx={{ fontSize: '12px', minHeight: '15px', ml: '5px' }}
          />
        );
      },
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'действие',
      hidden: false,
      renderRow: (row) => {
        const options = [
          ...Object.keys(BOOKING_STATUS)
            .map((key) => ({
              isHidden: key === 0,
              label: BOOKING_STATUS[key][lang],
              onClick: () => handleChangeStatus(row.apply_user.uuid, key),
            }))
            .filter((item) => !item.isHidden),
          { label: 'Удалить', onClick: () => handleDeleteApply(row.apply_user.uuid) },
        ];

        return options.length > 0 ? <ActionsButton options={options} /> : null;
      },
    },
  ];

  const handleToggleFilter = () => {};
  return (
    <Stack>
      {/* <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap" padding="20px">
        <Stack direction="row" gap="8px 24px" flexWrap="wrap" alignItems="center">
          <Button
            variant="outlined"
            startIcon={<AddIcon sx={{ color: '#9E6AFD' }} />}
            onClick={() => navigate(routes.userCreate.path.replace(':uuid', currentProject?.uuid))}
          >
            Один
          </Button>
          <Button
            endIcon={showFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            sx={{ '& .MuiButton-endIcon': { margin: 0 } }}
            onClick={handleToggleFilter}
          >
            фильтр
          </Button>
        </Stack>
      </Stack> */}

      {/* <Collapse in={showFilter}>
        <Box padding="0 20px" margin="16px 24px">
           <UserFilters onSubmit={handleFilter} /> 
        </Box>
      </Collapse> */}

      <MUITable
        id="project-booking-table"
        loading={isFetching}
        headCells={headCells}
        rows={list}
        rowsNum={0}
        // selectable
        onClickRow={(row) => {}}
      />
      {!!profileModalId && (
        <Dialog
          open={profileModalId}
          fullWidth
          maxWidth="lg"
          onClose={() => {
            setProfileModalId(null);
          }}
        >
          <DialogContent>{/* <ProfilePage isModal id={profileModalId} /> */}</DialogContent>
        </Dialog>
      )}
    </Stack>
  );
}
export default BookingList;
