import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ButtonLoading from 'src/components/button-loading';
import Loader from 'src/components/loader';
import { generalAPIs, projectAPIs } from 'src/services';
import { LOCALIZATION, MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { isArray } from 'lodash';
import { Clear } from '@mui/icons-material';

const BDK_OPTIONS = {
  withTable: { value: 'with_table', label: 'Со столом' },
  withoutTable: { value: 'without_table', label: 'Без стола' },
};

const ALLOW_SLOT_OPTIONS = {
  allow: { value: true, label: 'да' },
  deny: { value: false, label: 'Нет' },
};

const PROCESS_BDK_OPTIONS = {
  bdk: { value: 'bdk', label: 'БДК' },
  networking: { value: 'networking', label: 'Нетворкинг' },
};

function BDKSettings({ projectUuid }) {
  const fetchBDKSettings = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await projectAPIs.getBDKSetting(projectUuid);
    return response.message;
  };

  const fetchPackages = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await generalAPIs.getPackages(projectUuid);
    const raw = response.message;
    return raw && isArray(raw) ? raw : [];
  };

  const fetchRoles = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await generalAPIs.getRoles(projectUuid);
    const raw = response.message;
    return raw && isArray(raw) ? raw : [];
  };

  const { data: rolesList = [] } = useQuery(['fetchRoles', projectUuid], fetchRoles, { staleTime: 0 });

  const { data: packagesList = [] } = useQuery(['fetchPackages', projectUuid], fetchPackages, { staleTime: 0 });

  console.log('aaaaaaaaaaaa', packagesList);

  const { isLoading, data, refetch, isRefetching } = useQuery(['fetchBDKSettings', projectUuid], fetchBDKSettings, {
    fetchBDKSettings: !!projectUuid,
  });

  const [currentOption, setCurrentOption] = useState({
    allowIsBusySlot: ALLOW_SLOT_OPTIONS.deny.value,
    typeBDK: BDK_OPTIONS.withTable.value,
    typeProcessBDK: PROCESS_BDK_OPTIONS.bdk.value,
    payedMeetingCount: 0,
    packageUUIDs: [],
    rolesUUIDs: [],
    slotCreationPackageUUIDs: [],
  });

  useEffect(() => {
    if (data) {
      setCurrentOption(data);
    }
  }, [data]);

  const handleChangeOption = (option, value) => {
    console.log('sdfgdfgd', value);
    setCurrentOption((old) => ({ ...old, [option]: value }));
  };

  const handleUpdateBDK = async () => {
    try {
      await projectAPIs.updateBDKSetting(projectUuid, {
        typeBDK: currentOption.typeBDK,
        allowIsBusySlot: currentOption.allowIsBusySlot === 'true',
        typeProcessBDK: currentOption.typeProcessBDK,
        payedMeetingCount: +currentOption.payedMeetingCount,
        packageUUIDs: currentOption.packageUUIDs,
        rolesUUIDs: currentOption.rolesUUIDs,
        slotCreationPackageUUIDs: currentOption.slotCreationPackageUUIDs,
      });
      refetch();
      createNotification(MESSAGES[LOCALIZATION.ru_RU].UPDATE_SUCCESSFULLY, 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[LOCALIZATION.ru_RU].ERROR);
    }
  };

  return (
    <Card>
      <CardContent>
        {isLoading || isRefetching ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack gap="18px">
                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Выберите тип BDK, пожалуйста:</FormLabel>
                  <RadioGroup value={currentOption?.typeBDK} name="bdk-setting">
                    {Object.values(BDK_OPTIONS).map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={(e) => handleChangeOption('typeBDK', e.target.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {/* 
                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Разрешить забронировать занятый слот:</FormLabel>
                  <RadioGroup value={currentOption?.allowIsBusySlot} name="bdk-setting">
                    {Object.values(ALLOW_SLOT_OPTIONS).map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={(e) => handleChangeOption('allowIsBusySlot', e.target.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}

                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Выберите вариант проведения БДК (БДК/Нетворкинг):</FormLabel>
                  <RadioGroup value={currentOption?.typeProcessBDK} name="bdk-setting">
                    {Object.values(PROCESS_BDK_OPTIONS).map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={(e) => handleChangeOption('typeProcessBDK', e.target.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Максимальное количество встреч без покупки пакета</FormLabel>

                  <TextField
                    name="payedMeetingCount"
                    value={currentOption?.payedMeetingCount}
                    onChange={(e) => handleChangeOption('payedMeetingCount', e.target.value)}
                    length={5}
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>
                    Пакеты в которые входит безлимитное пользование Нетворкинг
                  </FormLabel>
                  <Select
                    notched
                    labelId="package-label"
                    multiple={true}
                    value={currentOption?.packageUUIDs || []}
                    onChange={(e) => handleChangeOption('packageUUIDs', e.target.value)}
                    sx={{
                      '& .MuiSelect-select': {
                        paddingRight: '4px !important',
                      },
                    }}
                    endAdornment={
                      isArray(currentOption?.packageUUIDs) && currentOption?.packageUUIDs?.length > 0 ? (
                        <IconButton onClick={() => handleChangeOption('packageUUIDs', [])} sx={{ marginRight: '14px' }}>
                          <Clear />
                        </IconButton>
                      ) : (
                        <></>
                      )
                    }
                  >
                    {packagesList.length === 0 && <Typography variant="body2">Нет вариантов</Typography>}
                    {packagesList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.localization.ru_RU.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Роли для которых доступен Нетворкинг</FormLabel>
                  <Select
                    notched
                    labelId="role-label"
                    multiple={true}
                    value={currentOption?.rolesUUIDs || []}
                    onChange={(e) => handleChangeOption('rolesUUIDs', e.target.value)}
                    sx={{
                      '& .MuiSelect-select': {
                        paddingRight: '4px !important',
                      },
                    }}
                    endAdornment={
                      isArray(currentOption?.roleUUIDs) && currentOption?.roleUUIDs?.length > 0 ? (
                        <IconButton onClick={() => handleChangeOption('rolesUUIDs', [])} sx={{ marginRight: '14px' }}>
                          <Clear />
                        </IconButton>
                      ) : (
                        <></>
                      )
                    }
                  >
                    {rolesList.length === 0 && <Typography variant="body2">Нет вариантов</Typography>}
                    {rolesList.map((item) => {
                      console.log('вввввввв', item);
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name.ru_RU}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Ограничить создание слотов в лк для пакетов</FormLabel>
                  <Select
                    notched
                    labelId="slotPackage-label"
                    multiple={true}
                    value={currentOption?.slotCreationPackageUUIDs || []}
                    onChange={(e) => handleChangeOption('slotCreationPackageUUIDs', e.target.value)}
                    sx={{
                      '& .MuiSelect-select': {
                        paddingRight: '4px !important',
                      },
                    }}
                    endAdornment={
                      isArray(currentOption?.slotCreationPackageUUIDs) &&
                      currentOption?.slotCreationPackageUUIDs?.length > 0 ? (
                        <IconButton
                          onClick={() => handleChangeOption('slotCreationPackageUUIDs', [])}
                          sx={{ marginRight: '14px' }}
                        >
                          <Clear />
                        </IconButton>
                      ) : (
                        <></>
                      )
                    }
                  >
                    {packagesList.length === 0 && <Typography variant="body2">Нет вариантов</Typography>}
                    {packagesList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.localization.ru_RU.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ButtonLoading
                  variant="contained"
                  sx={{ width: { xs: '100%', md: '150px' } }}
                  disabled={
                    data?.typeBDK === currentOption?.typeBDK &&
                    data?.allowIsBusySlot === currentOption?.allowIsBusySlot &&
                    data?.typeProcessBDK === currentOption?.typeProcessBDK &&
                    data?.payedMeetingCount === currentOption?.payedMeetingCount &&
                    data?.packageUUIDs === currentOption?.packageUUIDs &&
                    data?.rolesUUIDs === currentOption?.rolesUUIDs &&
                    data?.slotCreationPackageUUIDs === currentOption?.slotCreationPackageUUIDs
                  }
                  onClick={handleUpdateBDK}
                >
                  сохранить
                </ButtonLoading>
              </Stack>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default BDKSettings;
