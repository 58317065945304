import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MeetingStatus from 'src/components/status/MeetingStatus';
import { DEFAULT_TIMEZONE } from 'src/utils/time';
import ActionsButton from './ActionsButton';
import MeetingFilters from './MeetingFilter';

function MeetingList({ loading, isBezviz, statuses, activeTab, queryParams = {}, setQueryParams, onReloadTable }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const headCells = [
    {
      id: 'select',
      numeric: false,
      disablePadding: true,
      hideRightDivider: true,
      label: '',
    },
    {
      id: 'from',
      numeric: false,
      disablePadding: false,
      label: 'от',
    },
    {
      id: 'for',
      numeric: false,
      disablePadding: false,
      label: 'для',
    },
    {
      id: 'dateTime',
      numeric: false,
      disablePadding: false,
      label: 'дата и время',
    },
    {
      id: 'table',
      numeric: false,
      disablePadding: false,
      label: 'стол',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'статус',
    },
    ...(['hold', 'done'].includes(activeTab)
      ? []
      : [
          {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: '',
          },
        ]),
  ];

  const [showFilter, setShowFilter] = useState(false);

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);

  const projectUuid = currentProject?.uuid;
  const { page: queryPage, limit, query } = queryParams;
  const tab = activeTab;
  const rowsPerPage = parseInt(limit) || 20;
  const page = Math.max(queryPage || 1, 1);

  const handleChangePage = (event, newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({ ...queryParams, page: 1, limit: parseInt(event.target.value, 10) });
  };

  const { reset, register, getValues, handleSubmit } = useForm({
    defaultValues: { query: queryParams?.query || '' },
  });

  const handleSearch = ({ query }) => {
    if (query && query.trim()) {
      setQueryParams({ ...queryParams, query: query });
    } else {
      let _queryParams = queryParams;
      delete _queryParams.query;
      setQueryParams(_queryParams);
    }
  };

  const handleFilter = (filters) => {
    setQueryParams({ ...filters, tab: queryParams.tab });
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Stack>
      <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap" padding="20px">
        <Stack direction="row" gap="8px 24px" flexWrap="wrap">
          <Button variant="outlined" startIcon={<AddIcon sx={{ color: '#9E6AFD' }} />}>
            добавить встречу
          </Button>
          <Button
            endIcon={showFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            sx={{ '& .MuiButton-endIcon': { margin: 0 } }}
            onClick={handleToggleFilter}
          >
            фильтр
          </Button>
        </Stack>

        {/* <form noValidate onSubmit={handleSubmit(handleSearch)}>
          <Stack direction="row" gap="8px 24px" flexWrap="wrap">
            <TextField
              name="query"
              size="small"
              placeholder="Введите фамилию..."
              sx={{ minWidth: '240px' }}
              {...register('query')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: getValues('query') ? 'visible' : 'hidden' }}
                    onClick={() => {
                      reset();
                      handleSearch({ query: '' });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <Button type="submit" variant="contained">
              поиск
            </Button>
          </Stack>
        </form> */}
      </Stack>

      <Collapse in={showFilter}>
        <Box padding="0 20px" margin="0 24px">
          <MeetingFilters onSubmit={handleFilter} />
        </Box>
      </Collapse>

      <TableContainer id="meetings-table" component={Paper} sx={{ position: 'relative', marginBottom: '20px' }}>
        {loading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: 'absolute', inset: '0', zIndex: 2, background: '#ffffff40', padding: '60px 0' }}
          >
            <CircularProgress />
          </Stack>
        )}
        <Table size="medium" sx={{ minWidth: 750, minHeight: 150 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow sx={{ background: '#F9FAFC' }}>
              {headCells.map((headCell, indexHead) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  width={headCell.width}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                      {headCell.label}
                    </Typography>
                    {!headCell.hideRightDivider && indexHead < headCells.length - 1 && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                      />
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {counts[tab] === 0 && !loading ? (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <Typography align="center">Нет данных</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {list.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`row-${index}`}
                      role="checkbox"
                      sx={{ cursor: 'pointer' }}
                      //   onClick={() => navigate(`/projects/${row.uuid}`)}
                    >
                      <TableCell width="40px" sx={{ padding: '0 2px !important' }}>
                        <Checkbox />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Stack>
                            <Typography variant="subtitle2" color={theme.palette.text.primary}>
                              {row.companyOwner}
                            </Typography>
                            <Link href={`/profile/${row.ownerUuid}`} variant="caption" target="_blank">
                              {row.fullNameOwner || ''}
                            </Link>
                            <Typography variant="caption">{row.email}</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Stack>
                            <Typography variant="subtitle2" color={theme.palette.text.primary}>
                              {row.companyMember}
                            </Typography>
                            <Link href={`/profile/${row.memberUuid}`} variant="caption" target="_blank">
                              {row.fullNameMember || ''}
                            </Link>
                            <Typography variant="caption">{row.email}</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ width: 'fit-content', whiteSpace: 'nowrap', color: theme.palette.text.secondary }}
                        >
                          {`${moment.tz(row?.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${moment
                            .tz(row?.endTime, DEFAULT_TIMEZONE)
                            .format('HH:mm')}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ width: '60px', whiteSpace: 'nowrap', color: theme.palette.text.secondary }}
                        >
                          {row.tableNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <MeetingStatus status={row?.status} />
                      </TableCell>
                      {['hold', 'done'].includes(activeTab) ? (
                        <></>
                      ) : (
                        <TableCell>
                          <ActionsButton projectUuid={projectUuid} item={row} reloadTable={onReloadTable} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
        {counts[tab] > 0 && (
          <TablePagination
            labelRowsPerPage="Рядов на странице:"
            labelDisplayedRows={({ count, from, to }) => {
              return `${from}-${to} из ${count}`;
            }}
            page={page - 1}
            component="div"
            count={counts[tab]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginTop: '20px' }}
          />
        )}
      </TableContainer>
    </Stack>
  );
}

export default MeetingList;
