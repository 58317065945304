import { Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { editUser } from '../../store/actions/application';
import Button from '../button';
import DateInput from '../date-input';
import Input from '../input';
import Select from '../select';
import classes from './profile-edit-modal.module.sass';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { getLocalizationValue } from 'src/utils/common';

export default function ProfileEditModal({ onClose }) {
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const roles = useSelector((state) => state.application.roles);
  const countries = useSelector((state) => state.application.countries);
  const projects = useSelector((state) => state.application.projects);
  const handleSubmit = (values) => {
    editUser({
      ...values,
      citizenship: values.citizenship?.code,
      countryResidence: values.countryResidence?.code,
      birthday: moment(values.birthday).format('YYYY-MM-DD'),
      // maxMemberCount: parseInt(values.maxMemberCount)
    }).then((err) => {
      if (!err) {
        onClose();
      }
    });
  };

  if (!profileInfo) return null;
  const initialValues = {
    ...profileInfo,
    accreditedBy: null,
    mainRoleId: roles.find((role) => role.id === profileInfo.mainRoleId),
    citizenship: countries.find((c) => c.code === profileInfo.citizenship)?.code,
    countryResidence: countries.find((c) => c.code === profileInfo.countryResidence)?.code,
    birthday: moment(profileInfo.birthday).toDate(),
    projectUuid: projects.find((p) => p.uuid === profileInfo.projectUuids[0]),
  };

  return (
    <Dialog maxWidth="md" open={true} onClose={onClose}>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle closeButton>Редактирование юзера</DialogTitle>
            <DialogContent>
              <Stack marginTop="20px" gap="20px">
                <Input name="firstName" label="Имя" required className={classes.input} />
                <Input name="middleName" label="Отчество" className={classes.input} />
                <Input name="lastName" label="Фамилия" required className={classes.input} />
                <Input name="email" label="Email" required className={classes.input} />
                <Input name="phone" label="Телефон" className={classes.input} />
                <DateInput name="birthday" label="Дата рождения" className={classes.date} />
                <Select
                  name="citizenship"
                  label="Гражданство"
                  options={countries}
                  getOptionLabel={(item) => getLocalizationValue(item, 'name')}
                  getOptionValue={(item) => item.code}
                  className={classes.select}
                />
                <Select
                  name="countryResidence"
                  label="Страна проживания"
                  options={countries}
                  getOptionLabel={(item) => getLocalizationValue(item, 'name')}
                  getOptionValue={(item) => item.code}
                  className={classes.select}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outline-dark" onClick={onClose}>
                Отменить
              </Button>
              <Button variant="dark" type="submit">
                Сохранить
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
}
