import { ChevronLeft } from '@mui/icons-material';
import { Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import Loader from 'src/components/loader';
import VizitSection from 'src/components/vizit-section';
import { routes } from 'src/constants';
import UserLayout from 'src/layouts/UserLayout';
import { setProject } from 'src/store/actions/application';
import { createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import UserSearchSelect from '../vizit-detail/UserSearchSelect';

function VizitCreatePage() {
  const params = useParams();
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);

  const projectUuid = params.uuid;
  const userUuid = currentUser?.uuid;

  const handleGotoList = () => {
    navigate(routes.projectVizits.path.replace(':uuid', data?.uuid));
  };

  const handleGotoVizitDetail = (vizitUuid) => {
    navigate(`${routes.projectVizitDetail.path.replace(':uuid', projectUuid).replace(':vizitUuid', vizitUuid)}`);
  };

  const fetchProjectData = async () => {
    const response = await request(`/projects?uuid=${projectUuid}`);
    return response.message?.[0];
  };

  const { isLoading, data } = useQuery('fetchProjectDetail', fetchProjectData);

  // const {
  //   isLoading: isLoadingProfileInfo,
  //   refetch: refetchProfileInfo,
  //   isFetching: isFetchingProfileInfo,
  // } = useQuery(['getProfileInfo', currentUser], () => getProfileInfo(currentUser?.uuid), { enabled: !!currentUser });

  const fetchUserVizit = async ({ queryKey }) => {
    const [_, projectUuid, userUuid] = queryKey;
    try {
      const response = await request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`);
      createNotification('У этого пользователя уже есть визитка в этом проекте');
      setTimeout(() => {
        handleGotoVizitDetail(response.message.uuid);
      }, 1000);
      return true;
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
      return false;
    }
  };

  const {
    isLoading: isLoadingUserVizit,
    data: userVizit,
    refetch: refetchUserVizit,
  } = useQuery(['fetchUserVizit', projectUuid, userUuid], fetchUserVizit, {
    enabled: !!userUuid && !!projectUuid && currentUser?.accreditedBy !== '',
  });

  useEffect(() => {
    if (!isLoading && data) {
      setProject(data);
    }
  }, [isLoading, data]);

  // useEffect(() => {
  //   // if (currentUser) {
  //   //   getProfileInfo(currentUser.uuid);
  //   // }
  //   return clearProfileInfo;
  // }, [currentUser]);

  return (
    <UserLayout showExtraNavItems={true}>
      <Card>
        <CardContent>
          <Stack gap="20px">
            <Stack direction="row" flexWrap="wrap" gap="8px" alignItems="center">
              <IconButton onClick={handleGotoList}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h6">Добавить визитную карточку</Typography>
            </Stack>
            <UserSearchSelect
              projectUuid={projectUuid}
              onChangeUser={(user) => {
                setCurrentUser(user);
              }}
            />
            {currentUser?.uuid && (
              <>
                {/* {currentUser.accreditedBy !== '' ? (
                  <Typography color="error" variant="body1">
                    Этот пользователь был добавлено, не может создать визит
                  </Typography>
                ) : ( */}
                <>
                  {isLoadingUserVizit ? (
                    <Loader />
                  ) : !userVizit ? (
                    <VizitSection
                      projectUuid={data?.uuid}
                      userUuid={userUuid}
                      vizits={userVizit}
                      setVizits={(item) => {
                        setTimeout(() => {
                          handleGotoVizitDetail(item.uuid);
                        }, 500);
                      }}
                      onDeletedVizit={handleGotoList}
                      setMembersVizits={() => {}}
                    />
                  ) : (
                    <></>
                  )}
                </>
                {/* )} */}
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
      <ConfirmModal />
    </UserLayout>
  );
}

export default VizitCreatePage;
