import { LOCALIZATION } from 'src/utils/constant';
import * as constants from '../constants/application';
import { AUTH__LOGOUT } from '../constants/auth';

const initialState = {
  list: [],
  counts: {},
  projects: [],
  currentProject: null,
  countries: [],
  industries: [],
  roles: [],
  projectSettings: {
    avatarSize: { width: 100, height: 100 },
  },

  profileInfo: null,
  documents: [],
  orders: [],
  companies: [],
  qrCode: null,
  lang: LOCALIZATION.ru_RU,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case constants.APPLICATIONS_SETTINGS_MFA:
      return {
        ...state,
        qrCode: action.settingData.secret,
      };
    case constants.APPLICATIONS_GET_LIST:
      return {
        ...state,
        list: action.list,
        counts: {
          ...state.counts,
          ...action.counts,
        },
      };
    case constants.APPLICATIONS_UPDATE_LIST:
      return {
        ...state,
        list: [action.user, ...state.list],
        counts: state.counts + 1,
      };
    case constants.APPLICATIONS_GET_COUNTS:
      return {
        ...state,
        counts: action.counts,
      };
    case constants.APPLICATIONS_SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case constants.APPLICATIONS_GET_PROFILE_INFO:
      return {
        ...state,
        profileInfo: action.profileInfo,
        documents: action.documents,
        orders: action.orders,
        companies: action.companies,
      };
    case constants.APPLICATIONS_SET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
      };
    case constants.APPLICATIONS_UPDATE_PROFILE_INFO:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          ...action.profileInfo,
        },
      };
    case constants.APPLICATIONS_GET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case constants.APPLICATIONS_SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.currentProject,
      };
    case constants.APPLICATIONS_GET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    case constants.APPLICATIONS_GET_INDUSTRIES:
      return {
        ...state,
        industries: action.industries,
      };
    case constants.APPLICATIONS_GET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case constants.SET_PROJECT_SETTINGS:
      return {
        ...state,
        projectSettings: {
          ...state.projectSettings,
          ...action.payload,
        },
      };
    case AUTH__LOGOUT:
      return initialState;

    default:
      return state;
  }
}
