import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { LOCALIZATION } from '../../../constants';
import { userAPIs } from '../../../services';
import { vizitsValidationSchema } from '../../../utils/formValidator';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import classes from '../vizit-section.module.sass';

let isTyping;
function CompanyForm({
  projectUuid,
  userUuid,
  vizits,
  setVizits,
  uploadedLogo,
  setRequireLogo,
  setMembersVizits,
  isLocked,
}) {
  const lang = LOCALIZATION.ru_RU;
  const countries = useSelector((state) => state.application.countries);
  const industries = useSelector((state) => state.application.industries);
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        name: vizits?.name || company?.name || user?.properties?.companyName,
        country: vizits?.country || 643,
        region: vizits?.region || user?.properties?.legalFormCity,
        address: vizits?.address || user?.city || company?.extLegalAddress,
        telephone: vizits?.telephone || user?.legalFormTel || company?.phone,
        email: vizits?.email || company?.email || user?.email,
        web: vizits?.web || company?.webAddress || user?.properties?.legalFormWeb,
        scope: vizits?.scope,
        services: vizits?.services,
        description: vizits?.description,
        industry_id: vizits?.industry_id || 0,
      }),
      [vizits]
    ),
    resolver: yupResolver(vizitsValidationSchema),
  });

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const nameRef = useRef(null);
  const extLegalAddressRef = useRef(null);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionCompany(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  const renderSuggestion = useCallback(
    (field, ref, isAddress) => {
      const handleSelect = (suggestion) => {
        setFocusingField('');
        if (isAddress) {
          suggestion && setValue('address', suggestion);
          suggestion && setValue('region', suggestion.split(',')?.[0]);
        } else {
          suggestion.fullName && setValue('name', suggestion.shortName);
          suggestion.emails?.[0] && setValue('email', suggestion.emails?.[0]);
          suggestion.phones?.[0] && setValue('phone', suggestion.phones?.[0]);
          suggestion.city && setValue('region', suggestion.city);
          suggestion.address && setValue('address', suggestion.address);
        }
      };

      return (
        <Popper
          open={focusingField === field && suggestions?.length > 0}
          anchorEl={ref.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.suggestions}>
                <ClickAwayListener onClickAway={() => setFocusingField('')}>
                  <MenuList
                    sx={{
                      maxWidth: '50vw',
                      maxHeight: '450px',
                      overflow: 'scroll',
                    }}
                  >
                    {suggestions.map((suggest, i) => (
                      <MenuItem key={i} onClick={() => handleSelect(suggest)}>
                        {isAddress ? `${suggest}` : `${suggest.shortName} - ${suggest.managerName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    },
    [suggestions, focusingField]
  );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  const fetchUserVizitsData = async () => {
    try {
      const membersVizitsRes = await userAPIs.getMembersVizitsProject(projectUuid, userUuid);
      setMembersVizits(membersVizitsRes.message);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (values) => {
    // if (!vizits?.logo && !uploadedLogo) return setRequireLogo(true);
    if (isSubmitting) return;
    // if (!vizits && user.accreditedBy != '') {
    //   return createErrorNotification('Вы не можете создать визит');
    // }
    try {
      setIsSubmitting(true);
      const body = { ...values, country: parseInt(values.country) };
      const formData = new FormData();
      if (!vizits) {
        if (uploadedLogo) {
          formData.append('fileDocument', uploadedLogo, uploadedLogo?.name);
        }
        formData.append('vizit', JSON.stringify(body));
      }
      const res = vizits
        ? await userAPIs.patchUserVizit(projectUuid, userUuid, body)
        : await userAPIs.createUserVizit(projectUuid, userUuid, formData);
      setVizits(res.message);
      createNotification('Сохранено');
      setIsChanged(false);
      if (!vizits) {
        await fetchUserVizitsData();
      }
    } catch (e) {
      console.log(e);
      createErrorNotification('Ошибка');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      setIsChanged(Object.keys(values).some((key) => vizits?.[key] !== values?.[key]));
    });
    return () => subscription.unsubscribe();
  }, [watch, vizits]);

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={6.75}>
        <Grid item xs={6}>
          <Stack gap="20px">
            <div>
              <TextField
                required
                fullWidth
                label={'Название компании'}
                InputLabelProps={{ shrink: true }}
                inputRef={nameRef}
                defaultValue={getValues('name')}
                {...register('name')}
                onChange={(e) => handleChangeTextField(e, 'name')}
                error={errors.name ? true : false}
                helperText={errors.name?.message}
                disabled={isLocked}
              />
              {renderSuggestion('name', nameRef)}
            </div>

            <FormControl error={errors.country ? true : false} disabled={isLocked}>
              <InputLabel required id="select-label__country-company-vizit">
                Страна
              </InputLabel>

              <Select
                labelId="select-label__country-company-vizit"
                label="Страна"
                defaultValue={getValues('country')}
                {...register('country')}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.localization?.[lang]?.name}
                    {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                  </MenuItem>
                ))}
              </Select>
              {errors.country && (
                <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                  {errors.country.message}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              required
              label={'Регион'}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={getValues('region')}
              {...register('region')}
              error={errors.region ? true : false}
              helperText={errors.region?.message}
              disabled={isLocked}
            />
            <div>
              <TextField
                required
                label={'Адрес'}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={extLegalAddressRef}
                sx={{ width: '100%' }}
                defaultValue={getValues('address')}
                {...register('address')}
                onChange={(e) => handleChangeTextField(e, 'address', true)}
                error={errors.address ? true : false}
                helperText={errors.address?.message}
                disabled={isLocked}
              />
              {renderSuggestion('address', extLegalAddressRef, true)}
            </div>

            <MuiPhoneNumber
              required
              label={'Телефон'}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              name="telephone"
              autoFormat={false}
              defaultCountry={'ru'}
              {...register('telephone')}
              value={getValues('telephone')}
              onChange={(value) => setValue('telephone', value)}
              error={errors.telephone ? true : false}
              helperText={errors.telephone?.message}
              disabled={isLocked}
            />
            <TextField
              required
              label="E-mail"
              InputLabelProps={{
                shrink: true,
              }}
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              disabled={isLocked}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap="20px" sx={{ height: '100%' }}>
            <TextField
              required
              label={'Адрес сайта'}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={getValues('web')}
              {...register('web')}
              error={errors.web ? true : false}
              helperText={errors.web?.message}
              disabled={isLocked}
            />
            <FormControl error={errors.industry ? true : false} disabled={isLocked}>
              <InputLabel required id="select-label__industry-company-vizit">
                Отрасль
              </InputLabel>

              <Select
                labelId="select-label__industry-company-vizit"
                label="Отрасль"
                defaultValue={getValues('industry_id')}
                {...register('industry_id')}
              >
                {industries.map((industry) => (
                  <MenuItem key={industry.id} value={industry.id}>
                    {industry.localization?.[lang]?.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.industry && (
                <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                  {errors.industry.message}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              required
              label={'Сфера деятельности вашей компании'}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={getValues('scope')}
              {...register('scope')}
              error={errors.scope ? true : false}
              helperText={errors.scope?.message}
              disabled={isLocked}
            />
            <TextField
              required
              label={'Предлагаемая продукция / Услуги'}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={getValues('services')}
              {...register('services')}
              error={errors.services ? true : false}
              helperText={errors.services?.message}
              disabled={isLocked}
            />
            <TextField
              multiline
              InputLabelProps={{ shrink: true }}
              minRows={4}
              maxRows={4}
              label="Расскажите о вашей компании"
              placeholder={'Пожалуйста, расскажите о вашей компании'}
              defaultValue={getValues('description')}
              sx={{ flex: 2 }}
              {...register('description')}
              disabled={isLocked}
            />

            <Box>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                size="large"
                disabled={isSubmitting || !isChanged || isLocked}
              >
                {'Сохранить изменения'}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export default CompanyForm;
