import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Close } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getTableOptions, getTimeSlotOptions } from '..';
import { AvatarUser } from '../../../components/avatar-user';
import { DOCUMENT_TYPES, LOCALIZATION, MESSAGES, TEXT } from '../../../constants';
import { projectAPIs, userAPIs } from '../../../services';
import { getDocumentOfMember } from '../../../store/actions/vizit';
import { DEFAULT_UTC_OFFSET } from '../../../utils/constant';
import { membersVizitsValidationSchema } from '../../../utils/formValidator';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import classes from './members.module.sass';
import { timeZoneConverter, timeZone, DEFAULT_TIMEZONE } from '../../../utils/time';
import TextFieldPhone from 'src/@core/components/mui/textfield/TextFieldPhone';
import ReactDatePicker from 'react-datepicker';
import { useAbility } from 'src/hooks/useAbility';
import { ACCESSES } from 'src/utils/ability';
import { useQuery } from 'react-query';
import { without } from 'lodash';

function Members({ projectUuid, userUuid, member, membersVizits, setMembersVizits, setShowAdd }) {
  const lang = LOCALIZATION.ru_RU;
  const theme = useTheme();
  const ability = useAbility();
  const canEditVizit = ability.can(ACCESSES.EDIT_VIZIT);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [requireLogo, setRequireLogo] = useState(false);
  const [isReadyTalk, setIsReadyTalk] = useState(member ? member.isReadyTalk : true);
  const [slots, setSlots] = useState(member?.slots || []);
  const [showTableNumber, setShowTableNumber] = useState(false);

  const [addSlotsCustom, setAddSlotsCustom] = useState(false);

  const [timeSlot, setTimeSlot] = useState({
    tableNumber: 0,
    date: moment().format('YYYY-MM-DD'),
    slot: '',
    fromTime: '',
    toTime: '',
    duration: 0,
  });
  const [isAdding, setIsAdding] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        fullName: member?.fullName,
        isReadyTalk: member?.isReadyTalk,
        telephone: member?.telephone,
        email: member?.email,
        role: member?.role,
        description: member?.description,
        slots: '',
        tableNumber: 0,
      }),
      [member]
    ),
    resolver: yupResolver(membersVizitsValidationSchema),
  });
  const handleChange = async (file) => {
    if (isUploading) return;
    try {
      setIsUploading(true);
      setRequireLogo(false);
      if (!member) {
        setUploadedLogo(file);
      } else {
        const formData = new FormData();
        formData.append('fileDocument', file, file.name);
        const response = await userAPIs.uploadPhotoMembersVizitsProject(projectUuid, member.uuid, formData);
        setMembersVizits(membersVizits.map((m) => (m.uuid === member.uuid ? response.message : m)));
        createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const fetchBDKSettings = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await projectAPIs.getBDKSetting(projectUuid);
    return response.message;
  };
  const { data: BDKSettings } = useQuery(['fetchBDKSettings', projectUuid], fetchBDKSettings, {
    fetchBDKSettings: !!projectUuid,
  });

  useEffect(() => {
    let showTableList = BDKSettings && BDKSettings.typeBDK !== 'without_table';
    let defaultTableNumber = showTableList ? '' : 0;
    setShowTableNumber(showTableList);
    setTimeSlot((t) => {
      return { ...t, tableNumber: defaultTableNumber };
    });
  }, [BDKSettings]);

  const onSubmit = async (values) => {
    // if (!member && !member?.avatar && !uploadedLogo) return setRequireLogo(true);
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const body = { ...values, isReadyTalk: Boolean(isReadyTalk), slots };
      const formData = new FormData();
      if (!member) {
        // formData.append('fileDocument', uploadedLogo, uploadedLogo?.name);
        formData.append('memberVizit', JSON.stringify(body));
      }
      const res = member
        ? await userAPIs.patchMembersVizitsProject(projectUuid, member.userUuid, body)
        : await userAPIs.createMembersVizitsProject(projectUuid, userUuid, formData);

      if (member) {
        setMembersVizits(membersVizits.map((m) => (m.uuid === member.uuid ? res.message : m)));
        setIsEdit(false);
      } else {
        setMembersVizits([res.message].concat(membersVizits));
        setShowAdd(false);
      }

      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      setIsChanged(false);
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (member) {
      setSlots(member?.slots || []);
      setIsEdit(false);
    } else {
      setShowAdd(false);
    }
    reset();
  };

  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      setIsChanged(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleAddSlot = () => {
    setIsAdding(true);
    const { tableNumber, date, slot, fromTime, toTime, duration } = timeSlot;
    if (addSlotsCustom) {
      let newSlots = [];
      let fromTimeMoment = moment(fromTime);
      do {
        const nextFromTime = fromTimeMoment.clone().add(duration, 'minute');
        const start = moment(date).format('DD-MM-YYYY ') + fromTimeMoment.format('HH:mm');
        const end = moment(date).format('DD-MM-YYYY ') + nextFromTime.format('HH:mm');
        const _start = moment(start, 'DD-MM-YYYY HH:mm').unix() * 1000;
        const _end = moment(end, 'DD-MM-YYYY HH:mm').unix() * 1000;
        const startTime = timeZoneConverter(moment(_start), DEFAULT_UTC_OFFSET, timeZone(moment(_start).toDate()));
        const endTime = timeZoneConverter(moment(_end), DEFAULT_UTC_OFFSET, timeZone(moment(_end).toDate()));
        const newSlot = {
          tableNumber,
          startTime: startTime.unix() * 1000,
          endTime: endTime.unix() * 1000,
        };
        if (
          !slots.find(
            (slot) =>
              (newSlot.startTime > slot.startTime && newSlot.startTime < slot.endTime) ||
              (newSlot.endTime > slot.startTime && newSlot.endTime < slot.endTime) ||
              (newSlot.startTime < slot.startTime && newSlot.endTime > slot.endTime) ||
              (newSlot.startTime === slot.startTime && newSlot.endTime === slot.endTime)
          )
        ) {
          newSlots.push(newSlot);
        }
        fromTimeMoment = nextFromTime;
      } while (fromTimeMoment.isBefore(moment(toTime), 'minute'));

      setSlots(slots.concat(newSlots).sort((a, b) => a.startTime - b.startTime));
    } else {
      const start = moment(date).format('DD-MM-YYYY ') + slot?.split(' - ')?.[0];
      const end = moment(date).format('DD-MM-YYYY ') + slot?.split(' - ')?.[1];
      const _start = moment(start, 'DD-MM-YYYY HH:mm').unix() * 1000;
      const _end = moment(end, 'DD-MM-YYYY HH:mm').unix() * 1000;
      const startTime = timeZoneConverter(moment(_start), DEFAULT_UTC_OFFSET, timeZone(moment(_start).toDate()));
      const endTime = timeZoneConverter(moment(_end), DEFAULT_UTC_OFFSET, timeZone(moment(_end).toDate()));
      const newSlot = {
        tableNumber,
        startTime: startTime.unix() * 1000,
        endTime: endTime.unix() * 1000,
      };
      if (
        slots.find(
          (slot) =>
            (newSlot.startTime > slot.startTime && newSlot.startTime < slot.endTime) ||
            (newSlot.endTime > slot.startTime && newSlot.endTime < slot.endTime) ||
            (newSlot.startTime < slot.startTime && newSlot.endTime > slot.endTime) ||
            (newSlot.startTime === slot.startTime && newSlot.endTime === slot.endTime)
        )
      ) {
        createErrorNotification(MESSAGES[lang].SLOT_BUSY_ERROR);
        setTimeout(() => setIsAdding(false), 200);
        return;
      }
      setSlots(slots.concat([newSlot]).sort((a, b) => a.startTime - b.startTime));
    }
    setTimeSlot({
      date: moment().format('YYYY-MM-DD'),
      slot: '',
      tableNumber: 0,
      fromTime: '',
      toTime: '',
      duration: 0,
    });
    setTimeout(() => setIsAdding(false), 200);
  };

  const isEditing = !member || isEdit;
  const isLocked = member ? !isEdit : false;
  const ableAddSlot =
    timeSlot &&
    (timeSlot.tableNumber || !showTableNumber) &&
    timeSlot.date &&
    (timeSlot.slot ||
      (timeSlot.fromTime &&
        timeSlot.toTime &&
        moment(timeSlot.fromTime).isBefore(moment(timeSlot.toTime)) &&
        timeSlot.duration));

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const resAvatar = await getDocumentOfMember(member.userUuid, DOCUMENT_TYPES.AVATAR);
        setAvatar(resAvatar);
        setUploadedLogo(resAvatar);
        // setMembersVizits(membersVizits.map((m) => (m.uuid === member.uuid ? { ...m, avatarFile: avatar } : m)));
      } catch (e) {
        console.log(e);
      }
    };
    if (member) fetchAvatar();
  }, [member]);

  const formatMemberToUser = (member) => {
    return {
      ...member,
      uuid: member.userUuid,
      avatarFile: avatar,
      firstName: member.fullName.split(' ')?.[0] || '',
      lastName: member.fullName.split(' ')?.[1] || '',
    };
  };

  const tableOptions = getTableOptions();

  return (
    <>
      <Grid item xs={12} md={6}>
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <Card className={classes.card}>
            <Stack gap="20px">
              {member && !isEdit && canEditVizit && (
                <IconButton className={classes.editBtn} onClick={() => setIsEdit(true)}>
                  <ModeEditIcon />
                </IconButton>
              )}
              <Box>
                <Box className={classes.cardTitleBg} bgcolor={theme.palette.primary.main}></Box>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  gap="8px 32px"
                  flexWrap={['wrap', 'nowrap']}
                  zIndex={1}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  position="relative"
                >
                  {member ? (
                    <AvatarUser user={formatMemberToUser(member)} hideUploadAvatar={!isEditing} setAvatar={setAvatar} />
                  ) : (
                    <Stack style={{ position: 'relative' }}>
                      {/* <input
                    // ref={inputFileRef}
                    accept="image/jpg, image/jpeg, image/png"
                    className={classes.inputAvatar}
                    id="photo-member"
                    multiple={false}
                    type="file"
                    onChange={(e) => handleChange(e.target?.files?.[0])}
                  /> */}
                      <label htmlFor="photo-member">
                        <Avatar
                          className={classes.avatar}
                          src={
                            member?.avatar
                              ? member?.avatar + `?dummy=${new Date().getTime()}`
                              : uploadedLogo
                              ? URL.createObjectURL(uploadedLogo)
                              : ''
                          }
                        />
                      </label>
                    </Stack>
                  )}

                  <Stack className={classes.cardTitleInfo}>
                    <Tooltip title={member?.fullName}>
                      <Typography>{member?.fullName}</Typography>
                    </Tooltip>
                    {member && !isEdit && (
                      <FormControlLabel control={<Checkbox checked={isReadyTalk} />} label="Готов к общению" />
                    )}
                  </Stack>
                </Stack>
                {requireLogo && !uploadedLogo && !member && (
                  <Typography variant="subtitle2" color="red">
                    * {MESSAGES[lang].PLEASE_UPLOAD_AVATAR}
                  </Typography>
                )}
              </Box>

              {/* Form member vizit */}
              <Box>
                <Grid container spacing={5}>
                  {(isEdit || !member) && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox checked={isReadyTalk} onClick={(e) => setIsReadyTalk(e.target.checked)} />}
                        label="Готов к общению"
                        sx={{ padding: '10px 0' }}
                      />
                      <TextField
                        label={TEXT[lang].FULLNAME}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        defaultValue={getValues('fullName')}
                        {...register('fullName')}
                        error={errors.fullName ? true : false}
                        helperText={errors.fullName?.message}
                        disabled={isLocked}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <TextFieldPhone
                      label={TEXT[lang].NUMBER_TELEPHONE}
                      fullWidth
                      name="telephone"
                      {...register('telephone')}
                      value={getValues('telephone')}
                      onChange={(value) => setValue('telephone', value)}
                      error={errors.telephone ? true : false}
                      helperText={errors.telephone?.message}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="email"
                      label={TEXT[lang].EMAIL}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={getValues('email')}
                      {...register('email')}
                      error={errors.email ? true : false}
                      helperText={errors.email?.message}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={TEXT[lang].JOB_TITLE}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={getValues('role')}
                      {...register('role')}
                      error={errors.role ? true : false}
                      helperText={errors.role?.message}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      InputLabelProps={{ shrink: true }}
                      minRows={4}
                      maxRows={4}
                      label="О себе"
                      placeholder={TEXT[lang].PLEASE_TELL_ABOUT_YOURSELF}
                      defaultValue={getValues('description')}
                      {...register('description')}
                      disabled={isLocked}
                    />
                  </Grid>
                  {slots?.length > 0 && (
                    <Grid item xs={12}>
                      <Stack direction="row" flexWrap="wrap" gap="6px">
                        {slots.map((slot, i) => (
                          <div key={i} className={classes.slot}>
                            {`${momentTZ.tz(slot.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${momentTZ
                              .tz(slot.endTime, DEFAULT_TIMEZONE)
                              .format('HH:mm')}`}
                            {isEditing && (
                              <Close
                                sx={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}
                                onClick={() => {
                                  setSlots((old) => old.filter((_, iOld) => iOld !== i));
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </Stack>
                    </Grid>
                  )}
                  {!isAdding && isEditing && (
                    <>
                      <Grid item xs={12} sm={addSlotsCustom ? 6 : 3}>
                        <FormControl fullWidth error={errors.tableNumber ? true : false} disabled={isLocked}>
                          <InputLabel id="select-label__table" shrink>
                            {TEXT[lang].TABLE}
                          </InputLabel>
                          <Select
                            notched
                            labelId="select-label__table"
                            label={TEXT[lang].TABLE}
                            {...register('tableNumber')}
                            value={timeSlot?.tableNumber}
                            defaultValue={tableOptions[0]}
                            disabled={!showTableNumber}
                          >
                            {tableOptions
                              // .filter((option) => {
                              //   return (
                              //     slots.find((s) => moment.unix(s.startTime / 1000).isSame(moment(timeSlot.date), 'days'))
                              //       ?.tableNumber !== option
                              //   );
                              // })
                              .map((option, i) => (
                                <MenuItem
                                  key={i}
                                  value={option}
                                  onClick={() => setTimeSlot({ ...timeSlot, tableNumber: option })}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.tableNumber && (
                            <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                              {errors.tableNumber.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={addSlotsCustom ? 6 : 4}>
                        <TextField
                          fullWidth
                          type="date"
                          label={TEXT[lang].DATE}
                          InputLabelProps={{ shrink: true }}
                          value={timeSlot?.date}
                          onChange={(e) => setTimeSlot((old) => ({ ...old, date: e.target.value }))}
                          error={errors.date ? true : false}
                          helperText={errors.date?.message}
                          disabled={isLocked}
                        />
                      </Grid>
                      {addSlotsCustom ? (
                        <>
                          <Grid item xs={12} md={4}>
                            <ReactDatePicker
                              selected={timeSlot.fromTime}
                              onChange={(date) => setTimeSlot({ ...timeSlot, fromTime: date })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              dateFormat="h:mm aa"
                              minTime={moment().set('h', 10).set('minute', 0).set('second', 0).toDate()}
                              maxTime={moment().set('h', 21).set('minute', 0).set('second', 0).toDate()}
                              customInput={<TextField label="от" fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <ReactDatePicker
                              selected={timeSlot.toTime}
                              onChange={(date) => setTimeSlot({ ...timeSlot, toTime: date })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              dateFormat="h:mm aa"
                              minTime={
                                timeSlot.fromTime
                                  ? moment(timeSlot.fromTime).add(1, 'hour').toDate()
                                  : moment().set('h', 10).set('minute', 0).set('second', 0).toDate()
                              }
                              maxTime={moment().set('h', 21).set('minute', 0).set('second', 0).toDate()}
                              customInput={
                                <TextField
                                  label="К"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  error={
                                    timeSlot.fromTime &&
                                    timeSlot.toTime &&
                                    moment(timeSlot.fromTime).isAfter(moment(timeSlot.toTime))
                                  }
                                  helperText={
                                    timeSlot.fromTime &&
                                    timeSlot.toTime &&
                                    moment(timeSlot.fromTime).isAfter(moment(timeSlot.toTime))
                                      ? 'Неверное время'
                                      : ''
                                  }
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth error={errors.slots ? true : false} disabled={isLocked}>
                              <InputLabel id="select-label__slot-duration" shrink>
                                {'Продолжительность'}
                              </InputLabel>

                              <Select
                                notched
                                labelId="select-label__slot-duration"
                                label={'Продолжительность'}
                                value={timeSlot?.duration}
                                variant="outlined"
                              >
                                {[15, 20, 30, 40, 50, 60].map((option, i) => (
                                  <MenuItem
                                    key={i}
                                    value={option}
                                    onClick={() => setTimeSlot({ ...timeSlot, duration: option })}
                                  >
                                    {option} Минуты
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} sm={5}>
                          <FormControl fullWidth error={errors.slots ? true : false} disabled={isLocked}>
                            <InputLabel id="select-label__slot-21" shrink>
                              {TEXT[lang].CONVENIENT_SLOT}
                            </InputLabel>

                            <Select
                              notched
                              labelId="select-label__slot-21"
                              label={TEXT[lang].CONVENIENT_SLOT + 'ЭЭЭЭ'}
                              {...register('slots')}
                              value={timeSlot?.slot}
                              variant="outlined"
                            >
                              {getTimeSlotOptions()
                                .filter((option) => {
                                  return !slots.find((s) => {
                                    return (
                                      `${moment.unix(s.startTime / 1000).format('HH:mm')} - ${moment
                                        .unix(s.endTime / 1000)
                                        .format('HH:mm')}` === option &&
                                      moment.unix(s.startTime / 1000).isSame(moment(timeSlot.date), 'days')
                                    );
                                  });
                                })
                                .map((option, i) => (
                                  <MenuItem
                                    key={i}
                                    value={option}
                                    onClick={() => setTimeSlot({ ...timeSlot, slot: option })}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>
                            {errors.slots && (
                              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                                {errors.slots.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<Add />}
                          disabled={!ableAddSlot}
                          onClick={handleAddSlot}
                          sx={{ whiteSpace: 'nowrap', width: '100%' }}
                        >
                          Подтвердить слот
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={isLocked}
                          onClick={() => {
                            if (addSlotsCustom) {
                              setTimeSlot({ ...timeSlot, fromTime: '', toTime: '', duration: 0 });
                            }
                            setAddSlotsCustom(!addSlotsCustom);
                          }}
                          sx={{ whiteSpace: 'nowrap', width: '100%' }}
                        >
                          настроить
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>

                {isEditing && canEditVizit && (
                  <Grid container columnSpacing={5} rowSpacing={4} marginTop={2}>
                    <Grid item xs={12} md={6} className={classes.button} style={{ justifyContent: 'flex-start' }}>
                      <Button fullWidth variant="outlined" size="large" onClick={handleCancel} disabled={isSubmitting}>
                        {TEXT[lang].CANCEL}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.button} style={{ justifyContent: 'flex-end' }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        disabled={isSubmitting || isLocked}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Stack>
          </Card>
        </form>
      </Grid>
    </>
  );
}

export default Members;
