import { AccountCircle, Check, Clear, Download, LocalPrintshop, ImageSearch, Store, Edit } from '@mui/icons-material';
import { Avatar, Button, Fab, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditPartAnketaModal from 'src/components/anketa/EditPartAnketaModal';
import ActionsButton from 'src/components/atoms/button/ActionsButton';
import ButtonLoading from 'src/components/button-loading';
import { MUITable } from 'src/components/mui-table';
import UserStatus from 'src/components/status/UserStatus';
import { DOCUMENT_TYPES, routes } from 'src/constants';
import { useDownloadFile, useLanguage } from 'src/hooks';
import { useAbility } from 'src/hooks/useAbility';
import { projectAPIs, userAPIs } from 'src/services';
import { updateUserByProjectUuid } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { isSafari, preventSpreadEvent } from 'src/utils/common';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function UserList({ loading, onReloadTable }) {
  const navigate = useNavigate();
  const ability = useAbility();

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);
  const roles = useSelector((state) => state.application.roles);
  const lang = useSelector((state) => state.application.lang);

  const { localizeText, localizeMessage } = useLanguage();
  const { downloadFile } = useDownloadFile();

  const rolesById = useMemo(() => {
    return roles?.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }, [roles]);

  const tab = 'total';
  const projectUuid = currentProject?.uuid;
  const canAccrediteUser = ability.can(ACCESSES.ACCREDITE_USER);
  const canPrintBadge = ability.can(ACCESSES.PRINT_BADGE);
  const canEditPartAnketa = ability.can(ACCESSES.EDIT_PART_ANKETA);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEditPartAnketaModal, setShowEditPartAnketaModal] = useState(null);

  const handleUpdateUser = async ({ userUuid, isAccredited }) => {
    if (userUuid) {
      await updateUserByProjectUuid(userUuid, { isAccredited });
      onReloadTable();
    }
  };

  const handleDownloadTicket = async (userUuid) => {
    if (!userUuid) return;
    if (!currentProject) {
      return createErrorNotification('Требуется текущий проект');
    }

    try {
      const response = await userAPIs.downloadUserDocument(
        userUuid,
        DOCUMENT_TYPES.CONFIRMATION_LETTER,
        currentProject?.uuid
      );
      downloadFile(response, 'билет');
    } catch (error) {
      createNotification(localizeMessage.ERROR, 'error');
    }
    // const url = `/my-ticket/${userUuid}/${currentProject?.uuid}`;
    // if (isSafari) {
    //   navigate(url);
    // } else {
    //   window.open(url, '_blank');
    // }
  };

  const handleDownloadBadge = async (userUuid) => {
    try {
      const response = await projectAPIs.generateBadge(projectUuid, userUuid);
      downloadFile(response, 'бейдж');
      // const blobURL = URL.createObjectURL(response);
      // window.open(blobURL, '_blank').print();
      // eventBus.emit(EVENTS.OPEN_BADGE_FILE_MODAL, {
      //   images: [{ src: blobURL, blob: response }],
      //   userUuid: userUuid,
      //   projectUuid: projectUuid,
      // });
      createNotification('Загрузка прошла успешно', 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const headCells = [
    {
      id: 'icons',
      numeric: false,
      disablePadding: true,
      label: '',
      renderRow: (row) => {
        const hasBadge = row.badgeUuid != '';
        const isMerch = row.projectSettings?.[currentProject?.uuid]?.isGivenMerch;
        const isSelfRegister = row.accreditedBy == '';
        const isBadgePrinted = row.projectSettings?.[currentProject?.uuid]?.isBadgePrinted;
        return (
          <Stack direction="row" gap="4px">
            {isMerch && (
              <Tooltip title="Пользователь уже получил мерч">
                <Store color="warning" />
              </Tooltip>
            )}
            {isSelfRegister && (
              <Tooltip title="Пользователь создал анкета самостоятельно">
                <AccountCircle color="black" />
              </Tooltip>
            )}
            {hasBadge && (
              <Tooltip title="Бейдж был создан">
                <ImageSearch color="success" />
              </Tooltip>
            )}
            {isBadgePrinted && (
              <Tooltip title="Бейдж был напечатан">
                <LocalPrintshop color="success" />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      id: 'last_name',
      numeric: false,
      disablePadding: false,
      label: 'фамилия',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.lastName || ''}</Typography>,
    },
    {
      id: 'first_name',
      numeric: false,
      disablePadding: false,
      label: 'имя',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.firstName || ''}</Typography>,
    },
    {
      id: 'middle_name',
      numeric: false,
      disablePadding: false,
      label: 'отчество',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.middleName || ''}</Typography>,
    },
    {
      id: 'mainRoleId',
      numeric: false,
      disablePadding: false,
      label: 'роль',
      renderRow: (row) => {
        const role = rolesById[row?.projectSettings?.[currentProject?.uuid]?.mainRoleId];
        return <Typography variant="body2">{role?.name?.ru_RU}</Typography>;
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'статус',
      renderRow: (row) => {
        const isAccredited = row.projectSettings?.[currentProject?.uuid]?.isAccredited;
        const readyToAccreditate = row.projectSettings?.[currentProject?.uuid]?.readyToAccreditate;
        return <UserStatus isAccredited={isAccredited} readyToAccreditate={readyToAccreditate} />;
      },
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'электронная почта',
    },
    {
      id: 'telephone',
      numeric: false,
      disablePadding: false,
      label: 'номер телефона',
      renderRow: (row) => <Typography variant="body2">{row.phone}</Typography>,
    },
    {
      id: 'anketaCompanyName',
      numeric: false,
      disablePadding: false,
      label: 'Название компании',
      isSortable: true,
      // renderRow: (row) => <Typography variant="body2">{row.anketaCompanyName}</Typography>,
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'действие',
      hidden: !canAccrediteUser && !canPrintBadge && !canEditPartAnketa,
      renderRow: (row) => {
        const isAccredited = row.projectSettings?.[currentProject?.uuid]?.isAccredited;
        const readyToAccreditate = row.projectSettings?.[currentProject?.uuid]?.readyToAccreditate;
        const isMerch = row.projectSettings?.[currentProject?.uuid]?.isGivenMerch;
        const isBadgePrinted = row.projectSettings?.[currentProject?.uuid]?.isBadgePrinted;

        const options = [
          // {
          //   label: (
          //     <>
          //       <Groups fontSize="24px" />
          //       {localizeText.SHOW_MEMBERS}
          //     </>
          //   ),
          //   isHidden: !isAccreditedBy,
          //   color: 'error',
          //   onClick: () => {
          //     setSelectedRow(row);
          //     setOpenMemberList(true);
          //   },
          // },
          {
            label: (
              <>
                <Download />
                {localizeText.DOWNLOAD_BADGE}
              </>
            ),
            isHidden: !isAccredited || !canPrintBadge,
            color: 'error',
            onClick: () => {
              eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
                content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
                onOk: () => handleDownloadBadge(row.uuid),
              });
            },
          },
          {
            label: (
              <>
                <Download />
                {localizeText.DOWNLOAD_TICKET}
              </>
            ),
            isHidden: !isAccredited || !canPrintBadge,
            onClick: () => {
              handleDownloadTicket(row.uuid);
            },
          },
          {
            label: (
              <>
                <Check />
                {localizeText.APPROVE}
              </>
            ),
            isHidden: isAccredited || !readyToAccreditate || !canAccrediteUser,
            onClick: () => {
              handleUpdateUser({ userUuid: row.uuid, isAccredited: true });
            },
          },
          {
            label: (
              <>
                <Clear />
                {localizeText.DECLINE}
              </>
            ),
            isHidden: !isAccredited || !canAccrediteUser,
            onClick: () => {
              handleUpdateUser({ userUuid: row.uuid, isAccredited: true });
            },
          },
          {
            label: (
              <>
                {isMerch ? <Clear /> : <Check />}
                {isMerch ? 'Вернуть мерч' : 'Выдать мерч'}
              </>
            ),
            isHidden: !canPrintBadge,
            onClick: async () => {
              await updateUserByProjectUuid(row.uuid, { isGivenMerch: !isMerch });
              onReloadTable();
            },
          },
          {
            label: (
              <>
                {isBadgePrinted ? <Clear /> : <Check />}
                {isBadgePrinted ? 'Вернуть бейдж' : 'Выдать бейдж'}
              </>
            ),
            isHidden: !canPrintBadge,
            onClick: async () => {
              await updateUserByProjectUuid(row.uuid, { isBadgePrinted: !isBadgePrinted });
              onReloadTable();
            },
          },
          {
            label: (
              <>
                <Edit />
                Регистрация
              </>
            ),
            isHidden: !canEditPartAnketa,
            onClick: () => {
              setShowEditPartAnketaModal(row);
            },
          },
        ].filter((item) => !item.isHidden);

        return options.length > 0 ? <ActionsButton options={options} /> : null;
      },
    },
  ];

  const handleBulkAction = async (action, selectedRows) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      if (action === 'download_badge') {
        eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
          content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
          onOk: async () => {
            try {
              const response = await projectAPIs.massiveActions(projectUuid, { userUuids: selectedRows, action }, true);
              downloadFile(response, 'бейдж');
              // const blobURL = URL.createObjectURL(response);
              // window.open(blobURL, '_blank').print();
              // eventBus.emit(EVENTS.OPEN_BADGE_FILE_MODAL, {
              //   images: [{ src: blobURL, blob: response }],
              //   onPrint: async () => {
              //     try {
              //       const response = await projectAPIs.massiveActions(
              //         projectUuid,
              //         { userUuids: selectedRows, action: 'print_badge' },
              //         true,
              //       );
              //       const url = window.URL.createObjectURL(response);
              //       window.open(url, '_blank').print();
              //     } catch (error) {
              //       createErrorNotification(error.message.error || localizeMessage.ERROR);
              //     }
              //   },
              // });
            } catch (error) {
              createErrorNotification(error.message.error || localizeMessage.ERROR);
            }
          },
        });
        return;
      }
      const isBlobResponse = action === 'download_ticket';
      const response = await projectAPIs.massiveActions(
        projectUuid,
        { userUuids: selectedRows, action },
        isBlobResponse
      );

      if (isBlobResponse) {
        downloadFile(response, 'билет');
        // const url = window.URL.createObjectURL(response);
        // window.open(url, '_blank');
      } else {
        onReloadTable();
      }
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <MUITable
        id="project-registers-table"
        loading={loading || isSubmitting}
        headCells={headCells}
        rows={list}
        rowsNum={counts[tab]}
        selectable={canAccrediteUser || canPrintBadge}
        isDisableSelect={(row) => {
          const isAccredited = row.projectSettings?.[currentProject?.uuid]?.isAccredited;
          const readyToAccreditate = row.projectSettings?.[currentProject?.uuid]?.readyToAccreditate;
          return !isAccredited && !readyToAccreditate;
        }}
        renderBulkActions={(selectedRows) => (
          <>
            {canAccrediteUser && (
              <>
                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('approve_accreditation', selectedRows)}
                >
                  Подвердить
                </ButtonLoading>
                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('decline_accreditation', selectedRows)}
                >
                  отказать
                </ButtonLoading>
              </>
            )}
            {canPrintBadge && (
              <>
                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('download_badge', selectedRows)}
                >
                  {localizeText.DOWNLOAD_BADGE}
                </ButtonLoading>
                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('download_ticket', selectedRows)}
                >
                  скачать билет
                </ButtonLoading>

                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('give_merch', selectedRows)}
                >
                  Выдать мерч
                </ButtonLoading>

                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('retake_merch', selectedRows)}
                >
                  Вернуть мерч
                </ButtonLoading>
                {/* <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('issue_badge', selectedRows)}
                >
                  Выдать бейдж
                </ButtonLoading>

                <ButtonLoading
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => handleBulkAction('retake_badge', selectedRows)}
                >
                  Вернуть бейдж
                </ButtonLoading> */}
              </>
            )}
          </>
        )}
        onClickRow={
          ability.can(ACCESSES.SEE_PROFILE)
            ? (row) => navigate(routes.profile.path.replace(':id', row.uuid) + `?projectUuid=${projectUuid}`)
            : undefined
        }
      />

      <EditPartAnketaModal
        projectUuid={projectUuid}
        user={showEditPartAnketaModal}
        open={!!showEditPartAnketaModal}
        onClose={() => setShowEditPartAnketaModal(null)}
        onReloadTable={onReloadTable}
      />
    </Stack>
  );
}

export default UserList;
