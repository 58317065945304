export const APPLICATIONS_GET_LIST = 'APPLICATIONS_GET_LIST';
export const APPLICATIONS_GET_COUNTS = 'APPLICATIONS_GET_COUNTS';
export const APPLICATIONS_GET_PROFILE_INFO = 'APPLICATIONS_GET_PROFILE_INFO';
export const APPLICATIONS_UPDATE_PROFILE_INFO = 'APPLICATIONS_UPDATE_PROFILE_INFO';
export const APPLICATIONS_GET_PROJECTS = 'APPLICATIONS_GET_PROJECTS';
export const APPLICATIONS_SET_CURRENT_PROJECT = 'APPLICATIONS_SET_CURRENT_PROJECT';
export const APPLICATIONS_GET_COUNTRIES = 'APPLICATIONS_GET_COUNTRIES';
export const APPLICATIONS_GET_INDUSTRIES = 'APPLICATIONS_GET_INDUSTRIES';
export const APPLICATIONS_GET_ROLES = 'APPLICATIONS_GET_ROLES';
export const APPLICATIONS_SETTINGS_MFA = 'APPLICATIONS_SETTINGS_MFA';
export const APPLICATIONS_UPDATE_LIST = 'APPLICATIONS_UPDATE_LIST';
export const APPLICATIONS_SET_COMPANIES = 'APPLICATIONS_SET_COMPANIES';
export const APPLICATIONS_SET_DOCUMENTS = 'APPLICATIONS_SET_DOCUMENTS';
export const APPLICATIONS_SET_LANG = 'APPLICATIONS_SET_LANG';
export const SET_PROJECT_SETTINGS = `APPLICATIONS_SET_PROJECT_SETTINGS`;
