import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Controller, useForm } from 'react-hook-form';
import Select from 'src/@core/components/mui/select';
import { emailTemplateAPIs } from 'src/services';
import { LANGUAGES } from 'src/utils/constant';
import { LOCALIZATION, MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import classes from './email.module.sass';

export const FOR_EVENTS_OPTIONS = [
  { label: 'Регистрация на мероприятии', value: 'register_event' },
  { label: 'Прохождения аккредитации', value: 'accreditation_event' },
  { label: 'Отказ в аккредитации', value: 'refuse_accreditation_event' },
  { label: 'Назначение встречи', value: 'meeting_send' },
  { label: 'Подтверждение встречи', value: 'meeting_confirm' },
  { label: 'Отклонение встречи', value: 'meeting_declined' },
];

function TemplateForm({
  template,
  projectUuid,
  roleUuid,
  isRefetching,
  onRefetchData = () => {},
  onCancelAddNew = () => {},
  onCancel = () => {},
}) {
  const lang = LOCALIZATION.ru_RU;
  const [editorState, setEditorState] = useState();
  const [engEditorState, setEngEditorState] = useState();
  const [error, setError] = useState(false);

  const handleSubmitTemplate = async (values) => {
    if (!editorState) {
      setError(true);
      return;
    }
    const rawContentState = editorState ? convertToRaw(editorState.getCurrentContent()) : '';
    const htmlString = editorState ? draftToHtml(rawContentState) : '';
    const rawEngContentState = engEditorState ? convertToRaw(engEditorState.getCurrentContent()) : '';
    const engHtmlString = engEditorState ? draftToHtml(rawEngContentState) : '';

    try {
      if (template) {
        await emailTemplateAPIs.updateTemplate(template.uuid, {
          body: htmlString,
          englishBody: engHtmlString,
          ...values,
        });
        createNotification(MESSAGES[lang].UPDATE_SUCCESSFULLY, 'success');
      } else {
        await emailTemplateAPIs.createTemplate({
          projectUuid,
          roleUuid,
          body: htmlString,
          englishBody: engHtmlString,
          ...values,
        });
        createNotification(MESSAGES[lang].CREATE_SUCCESSFULLY, 'success');
        onCancelAddNew();
      }
    } catch (error) {
      console.error(error);
      createErrorNotification(error?.message?.error || MESSAGES[lang].ERROR);
    } finally {
      onRefetchData();
      onCancel();
    }
  };

  const formatHTMLStringToDraft = (htmlString) => {
    const blocksFromHtml = htmlToDraft(htmlString);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const handleCancel = () => {
    if (template) {
      setEditorState(formatHTMLStringToDraft(template.body || ''));
      setEngEditorState(formatHTMLStringToDraft(template.englishBody || ''));
    } else {
      onCancelAddNew();
    }
    onCancel();
  };

  useEffect(() => {
    if (template) {
      setEditorState(formatHTMLStringToDraft(template.body || ''));
      setEngEditorState(formatHTMLStringToDraft(template.englishBody || ''));
    }
  }, [template]);

  const [langue, setLangue] = useState(LANGUAGES[0].lang);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      forEvents: template?.forEvents ?? [],
      subject: template?.subject ?? '',
      sendAttachment: template?.sendAttachment ?? false,
    },
  });

  return (
    <form onSubmit={handleSubmit(handleSubmitTemplate)}>
      <Stack gap="24px" sx={{ width: '70%', margin: 'auto' }}>
        <Stack gap="16px">
          <Controller
            name={`forEvents`}
            control={control}
            render={({ field }) => (
              <Select
                label="Для события"
                multiple
                isClearable
                options={FOR_EVENTS_OPTIONS}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name={`subject`}
            control={control}
            render={({ field }) => (
              <TextField label="Укажите тему письма" value={field.value} onChange={field.onChange} />
            )}
          />
          <Controller
            name={`sendAttachment`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox label="Отправлять вложения" checked={field.value} onChange={field.onChange} />}
                label="Отправлять вложения"
              />
            )}
          />
        </Stack>

        <Stack direction="row" gap="16px">
          {LANGUAGES.map((lang) => (
            <Button
              key={lang.lang}
              variant={langue === lang.lang ? 'contained' : 'text'}
              color={langue === lang.lang ? 'primary' : 'secondary'}
              onClick={() => {
                setLangue(lang.lang);
              }}
              sx={{ whiteSpace: 'nowrap', paddingX: '16px' }}
              startIcon={<img src={lang.flag} alt="flag" style={{ width: '16px', height: '16px' }} />}
            >
              {lang.lang === LOCALIZATION.ru_RU ? 'РУССКИЙ ЯЗЫК' : 'АНГЛИЙСКИЙ ЯЗЫК'}
            </Button>
          ))}
        </Stack>

        <Stack>
          <ul>
            <li>
              <Typography
                variant="body2"
                style={{ fontWeight: 700, fontSize: '16px' }}
              >{`Заменить логин шаблоном {%login}`}</Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                style={{ fontWeight: 700, fontSize: '16px' }}
              >{`Заменить пароль шаблоном {%password}`}</Typography>
            </li>
          </ul>
          <Editor
            editorState={langue === LOCALIZATION.ru_RU ? editorState : engEditorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName={`${classes.editorClassName} ${error ? classes.error : ''}`}
            onEditorStateChange={(newState) => {
              setError(false);
              langue === LOCALIZATION.ru_RU ? setEditorState(newState) : setEngEditorState(newState);
            }}
            toolbar={{ image: { uploadEnabled: true } }}
          />
          {error && <Typography color="error">Поле не может быть пустым</Typography>}
        </Stack>
        <Box>
          <Grid container spacing={7.5}>
            <Grid item xs={12} md={6}>
              <Button fullWidth variant="outlined" size="large" onClick={handleCancel}>
                Отмена
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button fullWidth variant="contained" size="large" type="submit" disabled={isRefetching || isSubmitting}>
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </form>
  );
}

export default TemplateForm;
