import { Tooltip } from '@mui/material';
import React from 'react';
import ButtonLoading from 'src/components/button-loading';
import ConfirmL2Modal from 'src/components/confirm-l2-modal';
import { useAbility } from 'src/hooks/useAbility';
import { ACCESSES } from 'src/utils/ability';
import { projectAPIs } from 'src/services';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { useSelector } from 'react-redux';
import { useDownloadFile } from 'src/hooks';
import { updateUserByProjectUuid } from 'src/store/actions/application';

function DownloadBadgeButton({
  projectUuid,
  userUuid,
  disabled,
  isAccredited,
  isCurrentBadgeBlocked,
  // onEditBadgePrinted,
}) {
  const isStaff = useSelector((state) => state.auth.isStaff);
  const ability = useAbility();
  const { downloadFile } = useDownloadFile();

  const handleDownloadBadge = async () => {
    try {
      const response = await projectAPIs.generateBadge(projectUuid, userUuid);
      // downloadFile(response, 'бейдж');
      const blobURL = window.URL.createObjectURL(response);

      // eventBus.emit(EVENTS.OPEN_BADGE_FILE_MODAL, {
      //   images: [{ src: blobURL, blob: response }],
      //   userUuid: userUuid,
      //   projectUuid: projectUuid,
      // });
      createNotification('Загрузка прошла успешно, бейдж скоро откроется', 'success');
      eventBus.emit(EVENTS.OPEN_CONFIRM_L2_MODAL, {
        content: 'Данный бейдж был корректно распечатан?',
        cancelText: 'Нет',
        okText: 'Да',
        onCancel: async () => {
          await updateUserByProjectUuid(userUuid, {
            isBadgePrinted: false,
          });
        },
        onOk: async () => {
          await updateUserByProjectUuid(userUuid, {
            isBadgePrinted: true,
          });
        },
      });
      setTimeout(() => window.open(blobURL, '_blank').print(), 500);
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const canBlockAndGenNewBadge = ability.can(ACCESSES.BLOCK_AND_GEN_NEW_BADGE);

  if (!projectUuid || !userUuid) return null;

  return (
    <>
      {isCurrentBadgeBlocked && (isStaff || !canBlockAndGenNewBadge) ? (
        <Tooltip
          title="Текущий бейдж заблокирован. Только администратор может разблокировать и распечатать бейдж."
          placement="top"
        >
          <div style={{ width: '100%' }}>
            <ButtonLoading
              variant="outlined"
              disabled={disabled}
              sx={{ width: '100%' }}
              onClick={() => {
                if (isAccredited) {
                  eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
                    content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
                    onOk: handleDownloadBadge,
                  });
                  return;
                }
                eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
                  content:
                    'У данного пользователя нет аккредитации. Для выдачи бейджа, необходимо аккредитовать пользователя.',
                  onOk: () => {},
                });
              }}
            >
              Распечатать новый бейдж
            </ButtonLoading>
          </div>
        </Tooltip>
      ) : (
        <ButtonLoading
          variant="outlined"
          disabled={disabled}
          onClick={() => {
            if (isAccredited) {
              eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
                content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
                onOk: handleDownloadBadge,
              });
              return;
            }
            eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
              content:
                'У данного пользователя нет аккредитации. Для выдачи бейджа, необходимо аккредитовать пользователя.',
              onOk: () => {},
            });
          }}
        >
          Распечатать бейдж
        </ButtonLoading>
      )}

      <ConfirmL2Modal />
    </>
  );
}

export default DownloadBadgeButton;
